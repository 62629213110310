<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>运营管理</a-breadcrumb-item>
    <a-breadcrumb-item>抖小订阅消息推送（新剧）</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 日期： -->
    <span class="tool-title">日期：</span>
    <a-range-picker
      v-model:value="dateRange"
      :format="dateFormat"
    />
    <!-- 剧名 -->
    <!-- <span class="tool-title">剧名：</span>
    <a-input v-model:value="search" style="width: 160px;" placeholder="请输入ID或名称" /> -->
    <!-- 操作 -->
    <a-button type="primary" style="margin-left: 16px;" @click="handleSearch">查询</a-button>
    <a-button style="margin-left: 16px;" @click="handleReset">重置</a-button>
    <div style="flex: 1;"></div>
    <!-- 导出 -->
    <a-button style="margin-right: 16px;" @click="touchDownload">
      导出列表
    </a-button>
    <!-- 导出充值明细 -->
    <a-button style="margin-right: 16px;" @click="touchDownloadRechargeDetails">
      导出充值明细
    </a-button>
    <!-- 新建 -->
    <a-button type="primary" @click="touchEdit()">
      推送设置
    </a-button>
  </div>
  <!-- 列表 -->
  <div class="content-view">
    <!-- 列表 -->
    <a-table
      class="table-view"
      :data-source="dataSource"
      :columns="columns"
      :row-key="record => record.id"
      :pagination="pagination"
      :loading="isLoading"
      @change="handleTableChange"
    >
      <!-- 自定义行内容 -->
      <template #bodyCell="{ column, record }">
        <!-- 剧名 -->
        <!-- <template v-if="column.key === 'drama_name'">
          {{ record.project_drama_name || '-' }}
        </template> -->
        <!-- 推送时间 -->
        <!-- <template v-if="column.key === 'push_date'">
          <a-tooltip v-if="record[column.key]">
            <template #title>{{ record[column.key].substring(11, 19) }}</template>
            {{ record[column.key].substring(0, 10) }}
          </a-tooltip>
          <span v-else>{{ '-' }}</span>
        </template> -->
        <!-- 金额 -->
        <template v-if="column.key === 'push_amount_count'">
          {{ $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
        </template>
        <!-- 操作 -->
        <template v-if="column.key === 'operation'">
          <a class="operation-item" @click="touchDetail(record)">充值明细</a>
        </template>
      </template>
    </a-table>
    <!-- 当前可推送数 -->
    <div class="content-hint" v-if="dataSource.length">当前可推送数：{{ pushCount }}</div>
  </div>
  <!-- 新增 -->
  <Setting ref="RefSetting" @success="getList"></Setting>
  <!-- 充值明细 -->
  <RechargeDetails ref="RefRechargeDetails"></RechargeDetails>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import Pub from '@/utils/public'
import Setting from './components-subscription/Setting.vue'
import RechargeDetails from './components-subscription/RechargeDetails.vue'
import { pushListTt, exportPushTt } from '@/api/operate'

import * as Ex from "@netjoy/excelex"
import { RechargeTypes } from '@/utils/constantList'

// 推送设置
let RefSetting = ref(null)
// 充值明细
let RefRechargeDetails = ref(null)
// 加载
let isLoading = ref(false)
// 日期format
const dateFormat = 'YYYY-MM-DD'
// 日期范围
let dateRange = ref([dayjs().subtract(30, 'day'), dayjs()])
// let dateRange = ref(undefined)
// 搜索
let search = ref(undefined)
// 刷新时间
let lastTime = ref(undefined)
// 数据源
let dataSource = ref([])
// 当前可推送数
let pushCount = ref(0)
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '推送时间',
    dataIndex: 'push_date',
    key: 'push_date'
  },
  {
    title: '短剧',
    dataIndex: 'remark_name',
    key: 'remark_name',
  },
  {
    title: '推送数',
    dataIndex: 'push_count',
    key: 'push_count'
  },
  {
    title: '成功数',
    dataIndex: 'push_success_count',
    key: 'push_success_count'
  },
  {
    title: '访问用户',
    dataIndex: 'push_user_count',
    key: 'push_user_count',
  },
  {
    title: '充值用户',
    dataIndex: 'push_pay_user_count',
    key: 'push_pay_user_count',
  },
  {
    title: '充值金额',
    dataIndex: 'push_amount_count',
    key: 'push_amount_count'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 获取列表
  getList()
})

// 设置
function touchEdit () {
  RefSetting.value.showDrawer(1)
}

// 查询
function handleSearch () {
  // 设置分页
  pagination.current = 1
  // 获取列表
  getList()
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 重置
function handleReset () {
  // 筛选项重置
  dateRange.value = [dayjs().subtract(30, 'day'), dayjs()] // dayjs()
  search.value = undefined
  // 分页重置
  pagination.current = 1
  getList()
}

// 刷新接口
function refreshData () {
  popularizeRefreshData().then(res => {
    const { code, data } = res
    if (code === 0) {
      lastTime.value = data.last_time
      message.success('更新成功')
      handleSearch()
    }
  })
}

// 获取列表
function getList () {
  isLoading.value = true
  const params = {
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    kw: search.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  pushListTt(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      const list = data.data
      list.forEach(item => {
        item.children = item.item
      })
      dataSource.value = list
      pushCount.value = data.push_count
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  }).catch(() => {
    isLoading.value = false
  })
}

// 充值明细
function touchDetail (record) {
  const data = { ...record, platform_id: 1 }
  RefRechargeDetails.value.showDrawer(data)
}

// 导出数据
function touchDownload () {
  isLoading.value = true
  const params = {
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    kw: search.value,
    page: pagination.current,
    page_size: 20000
  }
  pushListTt(params).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0) {
      const list = data.data
      list.forEach(item => {
        item.children = item.item
      })
      if (list.length) {
        const columns = [
          {
            name: '推送时间',
            field: 'push_date'
          },
          {
            name: '剧名',
            field: 'remark_name'
          },
          {
            name: '推送数',
            field: 'push_count'
          },
          {
            name: '成功数',
            field: 'push_success_count'
          },
          {
            name: '访问用户',
            field: 'push_user_count'
          },
          {
            name: '充值用户',
            field: 'push_pay_user_count'
          },
          {
            name: '充值金额',
            field: 'push_amount_count'
          }
        ]
        const sheets = [
          {
            data: list,
            columns: columns
          }
        ]
        Ex.ex(sheets, function (item, field, json, sheetIndex, row, col, rowCount, colCount) {
          // row=0 为表头
          if (row != 0) {
            if (field === 'order_amount_count' || field === 'push_amount_count') {
              item.data = Pub.KEEP_NUMBER_DECIMAL((json[field] || 0) / 100, 2, false, true)
              return item
            }
          }
          // 无数据兼容
          // item.data = item.data || 0
          return item
        },  `运营管理-抖小订阅消息推送`)
      } else {
        message.error('暂无数据')
      }
    } else {
      message.error('导出失败')
    }
  }).catch(() => {
    message.error('导出失败')
  })
}

// 导出充值明细
function touchDownloadRechargeDetails () {
  isLoading.value = true
  const params = {
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    kw: search.value,
    page: pagination.current,
    page_size: 20000
  }
  exportPushTt(params).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0) {
      const list = data
      if (list.length) {
        const columns = [
          {
            name: '推送时间',
            field: 'push_date'
          },
          {
            name: '剧名',
            field: 'drama_name'
          },
          {
            name: '购买时间',
            field: 'pay_success_time'
          },
          {
            name: 'UID',
            field: 'user_id'
          },
          {
            name: '推广ID',
            field: 'adv_id'
          },
          {
            name: '购买内容',
            field: 'charge_type'
          },
          {
            name: '金额',
            field: 'total_amount'
          },
          {
            name: '购买页',
            field: 'buy_place'
          }
        ]
        const sheets = [
          {
            data: list,
            columns: columns
          }
        ]
        Ex.ex(sheets, (item, field, json, sheetIndex, row, col, rowCount, colCount) => {
          // row=0 为表头
          if (row != 0) {
            if (field === 'total_amount') {
              item.data = Pub.KEEP_NUMBER_DECIMAL((json[field] || 0) / 100, 2, false, true)
              return item
            } else if (field === 'charge_type') {
              item.data = RechargeTypes.find(item => item.value === json[field]).text
              return item
            } else if (field === 'buy_place') {
              item.data = `${json[field]}${json.eq_number != 0 ? `【第${json.eq_number}集】` : ''}`
              return item
            }
          }
          return item
        },  `运营管理-抖小订阅消息推送-充值明细`)
      } else {
        message.error('暂无数据')
      }
    } else {
      message.error('导出失败')
    }
  }).catch(() => {
    message.error('导出失败')
  })
}

</script>

<style scoped>
.alert-view {
  margin-top: 20px;
}
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}
.operation-item {
  margin-right: 8px;
}
.break-span{
  word-break: break-all;
  word-wrap: break-word;
}
.gap {
  display: inline-block;
  color: #ccc;
  margin: 0 6px;
}
.operation-item {
  margin-right: 8px;
}
.content-view {
  position: relative;
}
.content-hint {
  position: absolute;
  left: 0;
  bottom: 20px;
}
</style>